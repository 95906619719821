import { Component, Inject, OnInit, HostListener } from '@angular/core';
//import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig }  from '@angular/material/legacy-dialog';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AuthenticationService, SnackBarService } from '@app/shared/services';
import { DataStorageService } from '@app/shared/services/data-storage.service';
import esJson from '../../../assets/i18n/es.json';
import enJson from '../../../assets/i18n/en.json';
import { toNumbers } from 'canvg';
import { ConfirmationDialogComponent } from '@app/shared/components/confirmation-dialog/confirmation-dialog.component';
import saveAs from 'file-saver';

@Component({
    selector: 'app-notifications-dialog',
    templateUrl: './notifications-dialog.component.html',
    styleUrls: ['./notifications-dialog.component.scss'],
    providers: [ConfirmationDialogComponent],
    standalone: false
})
export class NotificationsDialogComponent implements OnInit {

  langJson = (localStorage.getItem('lang') === 'es') ? esJson : enJson;
  notifications = [];
  eventId: any;
  respuesta_notifications: any;
  sent_notifications = [];
  opened_notifications = [];
  scheduled_notifications = [];
  failed_notifications = [];
  type_of_notification = [];
  spinner: boolean;

  public getScreenWidth: any;
  public getScreenHeight: any;
  enviadas: boolean;
  abiertas: boolean;
  programadas: boolean;
  fallidas: boolean;

  constructor(
    public dialogRef: MatDialogRef<NotificationsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data_notif: any,
    public authService: AuthenticationService,
    public dataStorageService: DataStorageService,
    public ConfirmationDialogComponent: ConfirmationDialogComponent,
    public dialog: MatDialog,
    private snackbarService: SnackBarService
  ) { }

  ngOnInit(): void {
    this.spinner = true;
    this.authService.setLoader(true);
    this.getScreenWidth = window.innerWidth;
    this.getScreenHeight = window.innerHeight;
    this.eventId = JSON.parse(localStorage.getItem('eventId'));
    this.enviadas = true;
    this.abiertas = false;
    this.programadas = false;
    this.fallidas = false;
    this.fecha_desde = this.data_notif.inicio;
    this.fecha_hasta = this.data_notif.fin;
    this.cargar_notificaciones();
    this.authService.setLoader(false);
    this.spinner = false
  }

  onClose(){
    this.dialogRef.close();
  }

  cargar_notificaciones(){
    this.spinner = true;
    this.dataStorageService.fetchNotifications(this.data_notif.title).subscribe(response => {
      this.respuesta_notifications = response.data;
      this.opened_notifications = [];
      for(let i=0; i<this.respuesta_notifications.length; i++) {
        if(this.respuesta_notifications[i].sent){
          this.sent_notifications.push(this.respuesta_notifications[i].sent);
          for (let elemento of this.respuesta_notifications[i].sent.users) {
            if (elemento.opened_at != null) {
              this.opened_notifications.push(elemento);
            }
          }
        } else if(this.respuesta_notifications[i].failed){
          this.failed_notifications.push(this.respuesta_notifications[i].failed);
        } else if(this.respuesta_notifications[i].pending){
            this.scheduled_notifications.push(this.respuesta_notifications[i].pending);
        } else {
        }
      }

      if(this.enviadas && this.sent_notifications.length !== 0){
        this.notifications = this.sent_notifications[0].users;
      }
      if(this.enviadas && this.sent_notifications.length === 0){
        this.notifications = [];
      }
      if(this.abiertas && this.opened_notifications.length !== 0){
        this.notifications = this.opened_notifications;
      }
      if(this.abiertas && this.opened_notifications.length === 0){
        this.notifications = [];
      }
      if(this.programadas && this.scheduled_notifications.length !== 0){
        this.notifications = this.scheduled_notifications[0].users;
      }
      if(this.programadas && this.scheduled_notifications.length === 0){
        this.notifications = [];
      }
      if(this.fallidas && this.failed_notifications.length !== 0){
        this.notifications = this.failed_notifications[0].users;
      }
      if(this.fallidas && this.failed_notifications.length === 0){
        this.notifications = [];
      }

      this.filteredData = this.notifications;
      this.filtro_via();
      this.filtro_buscador();
      this.filtro_fecha();
      this.spinner = false;

    });
  }

  tipo_notif(tipo: string){
    if(tipo === "enviadas"){
      this.enviadas = true;
      this.abiertas = false;
      this.programadas = false;
      this.fallidas = false;
    }
    if(tipo === "abiertas"){
      this.enviadas = false;
      this.abiertas = true;
      this.programadas = false;
      this.fallidas = false;
    }
    if(tipo === "programadas"){
      this.enviadas = false;
      this.abiertas = false;
      this.programadas = true;
      this.fallidas = false;
    }
    if(tipo === "fallidas"){
      this.enviadas = false;
      this.abiertas = false;
      this.programadas = false;
      this.fallidas = true;
    }
  }

  filteredData = [];
  searchValue = '';

  filtro_buscador(){
    if (this.searchValue === '') {
      //this.filteredData = this.notifications.slice();
      // this.filtro_via();
    } else {
      // this.filtro_via();
      this.filteredData = this.filteredData.filter(data_filtrado =>
        data_filtrado.email.toLowerCase().includes(this.searchValue.toLowerCase())
      );
    }
  }

  flag_filtro = "ninguno";

  flag_filtros(tipo_filtro: any){
    if (tipo_filtro === "email"){
        if(this.flag_filtro !== "email"){
          this.flag_filtro = "email"
        } else {
          this.flag_filtro = "ninguno"
        }
    } else if (tipo_filtro === "push"){
        if(this.flag_filtro !== "push"){
          this.flag_filtro = "push"
        } else {
          this.flag_filtro = "ninguno"
        }
    } else if (tipo_filtro === "sms"){
        if(this.flag_filtro !== "sms"){
          this.flag_filtro = "sms"
        } else {
          this.flag_filtro = "ninguno"
        }
    } else if (tipo_filtro === "fecha"){
      if(this.flag_filtro !== "fecha"){
        this.flag_filtro = "fecha"
      } else {
        this.flag_filtro = "ninguno"
      }
  }
    // this.filtro_buscador();
    // this.filtro_fecha();
  }

  flag_icon = false

  click_icon_filtro(){
    this.flag_icon = !this.flag_icon

    if (this.flag_filtro !== "ninguno"){
      this.flag_icon_color = true
    }
    if (this.flag_icon){
      this.flag_icon_color = true
    }
    if (!this.flag_icon && this.flag_filtro === "ninguno"){
      this.flag_icon_color = false
    }

  }

  flag_icon_color = false;

  color_icon_filtro(){
    if (this.flag_filtro !== "ninguno"){
      this.flag_icon_color = true
    } else if (this.flag_icon = true && this.flag_filtro === "ninguno"){
      this.flag_icon_color = true
    } else {
      this.flag_icon_color = false
    }
  }


  filtro_via(){
    if(this.flag_filtro === "email"){
      this.filteredData = this.notifications.filter(data_filtrado =>
        data_filtrado.isEmail.toString().includes("true")
      );
    } else if(this.flag_filtro === "push"){
      this.filteredData = this.notifications.filter(data_filtrado =>
        data_filtrado.isPush.toString().includes("true")
      );
    } else if(this.flag_filtro === "sms"){
      this.filteredData = this.notifications.filter(data_filtrado =>
        data_filtrado.isSMS.toString().includes("true")
      );
    } else {
      this.filteredData = this.notifications.slice();
    }
  }

  fecha_desde: any;
  fecha_hasta: any;
  hoy: any;
  siempre: any;

  filtro_fecha(){
    // this.hoy = new Date().toLocaleDateString('en-CA') + ' 23:59:59';
    // this.hoy = new Date(Date.parse(this.hoy));
    // this.siempre = new Date(Date.parse('1949-01-13' + 'T00:00:00'))

    if (this.fecha_desde === '' || this.fecha_desde === undefined || this.fecha_desde === null) {
    } else {
        this.filteredData = this.filteredData.filter(data_filtrado =>
          new Date(Date.parse(data_filtrado.createdAt.replace(/[ ]+/g, "T"))) >= new Date(Date.parse(this.fecha_desde.replace(/[ ]+/g, "T")))
        );
    }

    if (this.fecha_hasta !== '' && this.fecha_hasta !== undefined && this.fecha_hasta !== null) {
        this.filteredData = this.filteredData.filter(data_filtrado =>
          new Date(Date.parse(data_filtrado.createdAt.replace(/[ ]+/g, "T"))) <= new Date(Date.parse(this.fecha_hasta.replace(/[ ]+/g, "T")))
        );
    }
  }

  openDownloadTicketPDF(mail: any){
    const dialogRef2 = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: '¿Quiere descargar la entrada?'
    });
    dialogRef2.afterClosed().subscribe(result => {
        if (result) {
          this.authService.setLoader(true);
          const filename = 'Entrada.pdf'
          this.dataStorageService.resendTicketInditex(mail).subscribe((response: any) => {
            const blob: any = new Blob([response], { type: 'application/pdf;charset=UTF-8; charset=utf-8' });
            saveAs(blob, filename);
            this.authService.setLoader(false);
            this.snackbarService.openSnackBar('Archivo descargado con exito')
          },
          err => {
            this.snackbarService.openSnackBar('Ha ocurrido un error descargando el archivo', err)
            this.authService.setLoader(false);
          });
        }
      });
  }



  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.getScreenWidth = window.innerWidth;
    this.getScreenHeight = window.innerHeight;
  }

}
